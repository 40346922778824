import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WorkTile from "../components/work-tile";

const WorkPage = ({ data }) => {
  const { nodes: work } = data.allWork;
  const { page_header } = data.collection.data;
  return (
    <Layout>
      <h1 className="font-serif text-6xl text-center mb-8">{page_header}</h1>
      <div className="max-w-6xl mx-auto flex flex-wrap">
        {work.map(({ data, uid }, index) => (
          <WorkTile key={index} uid={uid} {...data} />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allWork: allPrismicWork(sort: { fields: data___work_date, order: DESC }) {
      nodes {
        data {
          title {
            text
          }
          collection_image {
            fluid(maxWidth: 550) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
        uid
      }
    }
    collection: prismicWorkCollection {
      data {
        page_header
      }
    }
  }
`;

export default WorkPage;
